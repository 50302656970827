<template>
  <div class="mtop-200 width-center-80 font-sfns">
    <b-container fluid class="main-transactions">
      <b-row class="m-filter">
        <b-col cols="4">
          <span class="cursor-pointer" @click="toggleFilter()"><b-icon :icon="iconText"></b-icon></span>
        </b-col>
        <b-col cols="8" class="text-right">
          <span class="d-inline-block m-mright-50"><strong>{{ $t('filter') }}</strong></span>
          <span class="d-inline-block"><b-icon icon="filter-square-fill"></b-icon></span>
        </b-col>
        <transition name="fade">
          <b-col cols="12" class="mt-5" v-show="filterActive">
            <ValidationObserver v-slot="{}" ref="form">
              <form @change="filterTransactions" class="forms-sample">
                <div class="row text-right" dir="rtl">
                  <div class="col-md-6">
                    <ValidationProvider name="classification" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="classification" horizontal :label="$t('classification')">
                        <b-form-select id="classification" v-model="classification" :options="classifications"/>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('sub_classification')" rules="required">
                      <b-form-group slot-scope="{ errors }" label-for="sub_classification" horizontal :label="$t('sub_classification')">
                        <b-form-select id="sub_classification" :disabled="!subClassifications.length" v-model="sub_classification" :options="subClassifications"/>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="city_id" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="city_id" horizontal :label="$t('city')">
                        <b-form-select id="city_id" v-model="city_id" :options="cities" v-on:input='clearPlans'/>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="year_range" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="year_range" horizontal :label="$t('year_range')">
                        <date-picker v-model="yearRange" style="width:100%;" @change="filterTransactions" type="date" value-type="format" format="DD/MM/YYYY" range></date-picker>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
<!--                    <ValidationProvider name="plan_no" rules="">-->
<!--                      <b-form-group slot-scope="{ errors }" label-for="plan_no" horizontal :label="$t('plan_no')">-->
<!--                        <b-form-tags-->
<!--                          v-on:input="onChangeFetch"-->
<!--                          input-id="tags-basic"-->
<!--                          id="plan_no"-->
<!--                          v-model="plan_no" add-button-text="اضف"-->
<!--                          :placeholder="$t('plan_no')">-->
<!--                        </b-form-tags>-->
<!--&lt;!&ndash;                        <b-form-input type="text" id="plan_no" v-model="plan_no" :placeholder="$t('plan_no')"></b-form-input>&ndash;&gt;-->
<!--                        <p>{{ errors[0] }}</p>-->
<!--                      </b-form-group>-->
<!--                    </ValidationProvider>-->
                    <ValidationProvider name="date_range" rules="">
                      <b-form-group :label="$t('plans_list')" label-for="tags-with-dropdown">
                        <b-form-tags id="tags-with-dropdown" v-model="plan_no" no-outer-focus class="mb-2">
                          <template v-slot="{ tags, disabled, addTag, removeTag }">
                            <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                              <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                <b-form-tag
                                    @remove="removeTag(tag)"
                                    :title="tag"
                                    :disabled="disabled"
                                    variant="info"
                                >{{ tag }}</b-form-tag>
                              </li>
                            </ul>

                            <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
                              <template #button-content>
                                <b-icon icon="tag-fill"></b-icon> {{$t('choose_plan')}}
                              </template>
                              <b-dropdown-form @submit.stop.prevent="() => {}">
                                <b-form-group
                                    :label="$t('search_plans')"
                                    label-for="tag-search-input"
                                    label-cols-md="auto"
                                    class="mb-0"
                                    label-size="sm"
                                    description=""
                                    :disabled="disabled"
                                >
                                  <b-form-input
                                      v-model="term"
                                      id="tag-search-input"
                                      type="search"
                                      size="sm"
                                      autocomplete="off"
                                      v-on:input="fetchPlans"
                                  ></b-form-input>
                                </b-form-group>
                              </b-dropdown-form>
                              <b-dropdown-divider></b-dropdown-divider>
                              <b-dropdown-item-button
                                  v-for="option in plans"
                                  :key="option"
                                  @click="onOptionClick({ option, addTag })"
                              >
                                {{ option }}
                              </b-dropdown-item-button>
                              <b-dropdown-text v-if="plans.length === 0">
                                {{$t('no_plans')}}
                              </b-dropdown-text>
                            </b-dropdown>
                          </template>
                        </b-form-tags>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="plot_no" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="plot_no" horizontal :label="$t('plot_no')">
<!--                        <b-form-tags-->
<!--                          v-on:input="onChangeFetch"-->
<!--                          input-id="tags-basic"-->
<!--                          id="plot_no"-->
<!--                          v-model="plot_no"-->
<!--                          :placeholder="$t('plot_no')">-->

<!--                        </b-form-tags>-->
                        <b-form-input v-on:input="onChangeFetch" type="text" id="plot_no" v-model="plot_no" :placeholder="$t('plot_no')"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div> 
                  <div class="col-md-6">
                    <ValidationProvider name="min_amount" rules="numeric">
                      <b-form-group slot-scope="{ errors }" label-for="min_amount" horizontal :label="$t('min_amount')">
                        <b-form-input type="number" id="min_amount" v-model="min_amount" :placeholder="$t('max_amount')"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="max_amount" rules="numeric">
                      <b-form-group slot-scope="{ errors }" label-for="max_amount" horizontal :label="$t('max_amount')">
                        <b-form-input type="number" id="max_amount" v-model="max_amount" :placeholder="$t('max_amount')"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="from_meter_square" rules="numeric">
                      <b-form-group slot-scope="{ errors }" label-for="from_meter_square" horizontal :label="$t('from_meter_sq')">
                        <b-form-input type="number" id="from_meter_square" v-model="from_meter_sq" :placeholder="$t('from_meter_sq')"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="to_meter_square" rules="numeric">
                      <b-form-group slot-scope="{ errors }" label-for="to_meter_square" horizontal :label="$t('to_meter_sq')">
                        <b-form-input type="number" id="to_meter_square" v-model="to_meter_sq" :placeholder="$t('to_meter_sq')"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="district">
                      <b-form-group slot-scope="{ errors }" label-for="district" horizontal :label="$t('district')">
                        <b-form-input type="text" id="district" v-model="district" :placeholder="$t('district')"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <!--
                    <ValidationProvider name="hij_start_date">
                      <b-form-group slot-scope="{ errors }" label-for="hij_start_date" horizontal :label="$t('hij_start_date')">
                        <b-form-input type="text" id="hij_start_date" v-model="hij_start_date" placeholder="1430/09/08"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="hij_end_date">
                      <b-form-group slot-scope="{ errors }" label-for="hij_end_date" horizontal :label="$t('hij_end_date')">
                        <b-form-input type="text" id="hij_end_date" v-model="hij_end_date" placeholder="1430/09/08"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="invoice_no" rules="numeric">
                      <b-form-group slot-scope="{ errors }" label-for="invoice_no" horizontal :label="$t('invoice_number')">
                        <b-form-input type="number" id="invoice_no" v-model="invoice_no" :placeholder="$t('invoice_number')"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider> -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-right" dir="rtl">
                    <hr />
                    <ValidationProvider name="trans_with_coord" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="trans_with_coord" horizontal :label="$t('with_coordinates')">
                        <!--
                        <b-form-select id="trans_with_coord" v-model="trans_with_coord" :options="coordOptions"/> -->
                        <b-form-checkbox switch v-model="trans_with_coord" size="lg"></b-form-checkbox>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </b-col>
        </transition>
      </b-row>
      <b-row class="m-search">
        <b-col cols="2">
        <b-form-input type="number" @change="filterTransactions()" v-model="paginate"></b-form-input>
        </b-col>
        <b-col cols="5">
        </b-col>
        <b-col cols="5" class="text-right">
          <download-excel
            class="btn btn-success"
            :data="transactions"
            :fields="json_fields"
            worksheet="My Worksheet"
            name="transaction.xlsx">
            حفظ
          </download-excel>
<!--          <b-button @click="exportData()" class="btn gr-btn mr-3">حفظ</b-button> &lt;!&ndash;-->
<!--          <b-button class="btn bl-btn">بحث</b-button> &ndash;&gt;-->
        </b-col>

      </b-row>

<!--      Chart -->
      <b-row class="m-search" v-if='login_error || stat_loading || statOne.series.length || statTwo.series.length'>
        <p class='error-title' v-if='login_error'>
          {{login_error.message}}
          <a href="/home?login=true" v-if='login_error.status'>{{$t('login')}}</a>
        </p>
        <section class='spinner-wrap' v-if='!login_error && stat_loading'>
          <b-spinner label="Spinning"></b-spinner>
          <b-spinner type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="success" label="Spinning"></b-spinner>
          <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
        </section>
        <section v-if='!login_error && !stat_loading'>
          <div>
            <p class='chart-title'>{{$t('class_by_year')}} {{$t(this.chartCategoryType)}}</p>
            <vue-apex-charts
                :width="((80/100)*windowWidth) - 50"
                height="350" type="area"
                :options="statOne.chartOptions"
                :series="statOne.series"
            >
            </vue-apex-charts>
          </div>
          <div>
            <p class='chart-title'>{{$t('avg_price_by_year')}} {{$t(this.chartCategoryType)}}</p>
            <vue-apex-charts
                :width="((80/100)*windowWidth) - 50"
                height="350" type="line"
                :options="statFive.chartOptions"
                :series="statFive.series"
            >
            </vue-apex-charts>
          </div>
          <div>
            <p class='chart-title'>{{$t('volume_by_year')}} {{$t(this.chartCategoryType)}}</p>
            <vue-apex-charts
                :width="((80/100)*windowWidth) - 50"
                height="350" type="line"
                :options="statSix.chartOptions"
                :series="statSix.series"
            >
            </vue-apex-charts>
          </div>
          <div>
            <p class='chart-title'>{{$t('avg_price_subclass')}}</p>
            <vue-apex-charts
                :width="((80/100)*windowWidth) - 50"
                height="350" type="bar"
                :options="statTwo.chartOptions"
                :series="statTwo.series"
            >
            </vue-apex-charts>
          </div>
          <div>
            <p class='chart-title'>{{$t('transact_subclass')}}</p>
            <vue-apex-charts
                :width="((80/100)*windowWidth) - 50"
                height="350" type="bar"
                :options="statThree.chartOptions"
                :series="statThree.series"
            >
            </vue-apex-charts>
          </div>
          <div>
            <p class='chart-title'>{{$t('sum_by_subclass')}}</p>
            <vue-apex-charts
                :width="((80/100)*windowWidth) - 50"
                height="350" type="bar"
                :options="statFour.chartOptions"
                :series="statFour.series"
            >
            </vue-apex-charts>
          </div>
        </section>
      </b-row>

      <b-row class="table-cover">
        <section class='spinner-wrap' v-if='transact_loading'>
          <b-spinner label="Spinning"></b-spinner>
          <b-spinner type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="success" label="Spinning"></b-spinner>
          <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
        </section>
       <b-col cols="12" v-if='!transact_loading'>
         <p class="text-right">الصفقات</p>
       </b-col>
       <b-col cols="12" v-if='!transact_loading'>
        <b-table ref="table" :items="transactions" :busy="isBusy" id="table-list" responsive :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
          <template v-slot:cell(classification)="data">
            <span>{{ transText(data.item.classification) }}</span>
          </template>
          <template v-slot:cell(sub_classification)="data">
            <span>{{ transText(data.item.sub_classification) }}</span>
          </template>
          <template v-slot:cell(amount)="data">
            <span>{{ formatNumber(data.item.amount) }}</span>
          </template>
          <template v-slot:cell(meter_sq)="data">
            <span>{{ formatNumber(data.item.meter_sq) }}</span>
          </template>
          <template v-slot:cell(action)="data">
            <b-button :disabled="data.item.plot_coord == null" :to="{name: 'plot', params: {plotId: data.item.plot_no}, query: {plan: data.item.plan, meter_sq: data.item.meter_sq, coords: joinCoords(data.item.plot_coord), city_id: data.item.city_id}}" class="btn bl-btn">{{ $t('view') }}</b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          last-number
          aria-controls="table-list"
          align="right">
        </b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>
import _ from 'lodash'
import { ValidationObserver} from 'vee-validate'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import i18n from '@/i18n/i18nData'
import utils from "@/helper/utils.js"
import VueApexCharts from 'vue-apexcharts'
import exportFromJSON from 'export-from-json'
import {formatLabel} from '../store/transaction/moduleTransactionActions';

export default {
  title: "Transactions",
  data() {
    return {
      sortBy: 'name',
      windowWidth: window.innerWidth,
      sortDesc: false,
      isBusy: true,
      currentPage: 1,
      perPage: 0,
      paginate: 12,
      filterActive: false,
      classification: "",
      sub_classification: "",
      invoice_no: "",
      city_id: "",
      min_amount: "",
      yearRange: "",
      plan_no: [],
      plot_no: '',
      district: "",
      max_amount: "",
      from_meter_sq: "",
      to_meter_sq: "",
      hij_start_date: "",
      hij_end_date: "",
      trans_with_coord: "",
      stat_loading: false,
      transact_loading: false,
      term: "",
      login_error: null,
      fields: [
        { key: 'action', label: i18n.ar['action']},
        { key: 'price_per_sq_meter', label: i18n.ar['price_per_sq_meter'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'amount', label: i18n.ar['amount'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'hij_date', label: i18n.ar['hij_date'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'meter_sq', label: i18n.ar['meter_sq'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        //{ key: 'invoice_no', label: i18n.ar['invoice_no'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'sub_classification', label: i18n.ar['sub_classification'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'classification', label: i18n.ar['classification'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'plot_no', label: i18n.ar["plot_no"], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'plan', label: i18n.ar["plan_no"], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'district', label: i18n.ar['district'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'city', label: i18n.ar['city'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
      ],
      classifications: [
        {value: "", text: i18n.ar["select_classification"]},
        {value: "Commercial", text: i18n.ar["commercial"]},
        {value: "Residential", text: i18n.ar["residential"]}
      ],
      coordOptions: [
        {value: true, text: "True"},
        {value: "", text: "False"}
      ],
      json_fields: {
        'ID': 'id',
        'Plot ID': 'plot_id',
        'رقم القطعة': 'plot_no',
        'Plot Coordinates': 'plot_coord',
        'رقم المخطط': 'plan',
        'Blocks': 'blocks',
        'Invoice No': 'invoice_no',
        'EN Date': 'en_date',
        'HIJ Date': 'hij_date',
        'مساحة القطعة': 'meter_sq',
        'Amount': 'amount',
        'Classification': 'classification',
        'Sub classification': 'sub_classification',
        'City': 'city',
        'City ID': 'city_id',
        'District': 'district',
        'Price Per Meter sq': 'price_per_sq_meter',
        'Created At': 'created_at',
        'Updated At': 'updated_at'
      }
    }
  },
  components: {
    DatePicker,
    ValidationObserver,
    VueApexCharts,
  },
  computed: {
    chartCategoryType() {
      return this.$store.state.transaction.statOne.length && this.$store.state.transaction.statOne[0].categories
          && this.isNumeric(this.$store.state.transaction.statOne[0].categories[0]) ? 'year' : 'month'
    },
    transactions() {
      return this.$store.state.transaction.transactions
    },
    cities() {
      return this.$store.getters["city/listCities"]
    },
    subClassifications() {
      if (this.classification == "") return []

      let classifications = this.$store.state.transaction.classifications

      let clas = []

      classifications.forEach((cla) => {
        if (cla.classification === this.classification) {
          let name = cla.name.toLowerCase().split(" ").join("_")
          clas.push({value: cla.id, text: i18n.ar[name]})
        }
      })
      return clas
    },
    rows() {
      return this.$store.state.transaction.meta.total
    },
    iconText() {
      return this.filterActive ? "chevron-up" : "chevron-down"
    },
    plans() {
      let data = []
      this.$store.state.plan.plans2.map(item => data = [...data, item.planNo])
      return [...new Set(data)]
    },
    statOne() {
      return {
        type: 'line',
        chartOptions: {
          chart: { id: 'basic-bar'},
          xaxis: {
            categories: this.$store.state.transaction.statOne.length && this.$store.state.transaction.statOne[0].categories
                ? this.$store.state.transaction.statOne[0].categories
                : []
          }
        },
        series: this.$store.state.transaction.statOne.length && this.$store.state.transaction.statOne[0].series
            ? this.$store.state.transaction.statOne[0].series
            : []
      }
    },
    statFive() {
      return {
        type: 'line',
        chartOptions: {
          chart: { id: 'basic-bar'},
          yaxis: {
            labels: {
              formatter: function (value) {return formatLabel(value, 1)}
            },
          },
          xaxis: {
            categories: this.$store.state.transaction.statOne.length && this.$store.state.transaction.statOne[1].categories
                ? this.$store.state.transaction.statOne[1].categories
                : []
          }
        },
        series: this.$store.state.transaction.statOne.length && this.$store.state.transaction.statOne[1].series
            ? this.$store.state.transaction.statOne[1].series
            : []
      }
    },
    statSix() {
      return {
        chartOptions: {
          chart: { id: 'basic-bar'},
          yaxis: {
            labels: {
              formatter: function (value) {return formatLabel(value, 1)}
            },
          },
          xaxis: {
            categories: this.$store.state.transaction.statOne.length && this.$store.state.transaction.statOne[2].categories
                ? this.$store.state.transaction.statOne[2].categories
                : []
          }
        },
        series: this.$store.state.transaction.statOne.length && this.$store.state.transaction.statOne[2].series
            ? this.$store.state.transaction.statOne[2].series
            : []
      }
    },
    statTwo() {
      return {
        type: 'bar',
        chartOptions: {
          chart: { id: 'basic-bar' },
          yaxis: {
            labels: {
              formatter: function (value) {return formatLabel(value, 1)}
            },
          },
          xaxis: {
            categories: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[0].categories
                ? this.$store.state.transaction.statTwo[0].categories
                : []
          }
        },
        series: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[0].series
            ? this.$store.state.transaction.statTwo[0].series
            : []
      }
    },
    statThree() {
      return {
        chartOptions: {
          chart: { id: 'basic-bar' },
          xaxis: {
            categories: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[1].categories
                ? this.$store.state.transaction.statTwo[1].categories
                : []
          }
        },
        series: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[1].series
            ? this.$store.state.transaction.statTwo[1].series
            : []
      }
    },
    statFour() {
      return {
        chartOptions: {
          chart: { id: 'basic-bar' },
          yaxis: {
            labels: {
              formatter: function (value) {return formatLabel(value, 1)}
            },
          },
          xaxis: {
            categories: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[2].categories
                ? this.$store.state.transaction.statTwo[2].categories
                : []
          }
        },
        series: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[2].series
            ? this.$store.state.transaction.statTwo[2].series
            : []
      }
    }
    // statTwo: function () {
    //   return id => {
    //     return {
    //       chartOptions: {
    //         chart: { id: 'basic-bar' },
    //         xaxis: {
    //           categories: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[id].categories
    //               ? this.$store.state.transaction.statTwo[id].categories
    //               : []
    //         }
    //       },
    //       series: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[id].series
    //           ? this.$store.state.transaction.statOne[id].series
    //           : []
    //     }
    //   }
    // }
  },
  watch: {
    currentPage() {
      if(this.currentPage != this.$store.state.transaction.meta.current_page)
        this.fetchTransactions(this.currentPage)
    },
  },
  methods: {
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    filterTransactions: _.debounce(function() {
      this.fetchTransactions(this.currentPage)
    }, 2000),
    onChangeFetch() {
      this.fetchTransactions(1, false)
    },
    fetchTransactions(current_page = 1, noLoad) {
      this.transact_loading = true
      // let loader = this.$loading.show({opacity: 0.9})

      // if (noLoad === true) loader.hide()
      if (noLoad === true) this.transact_loading = false

      let plans = ''
      this.plan_no.map(item => plans = `${plans}${plans === '' ? '' : ','}${item}`)

      const payload = {
        meta: {
          page: current_page,
          paginate: this.paginate,
          classification: this.classification,
          sub_classification_id: this.sub_classification,
          invoice_no: this.invoice_no,
          city_id: this.city_id,
          min_amount: this.min_amount,
          start_date: this.yearRange.length ? this.yearRange[0] : "",
          end_date: this.yearRange.length ? this.yearRange[1]: "",
          plan_no: plans,
          plot_no: this.plot_no,
          district: this.district,
          max_amount: this.max_amount,
          from_meter_sq: this.from_meter_sq,
          to_meter_sq: this.to_meter_sq,
          hij_start_date: this.hij_start_date,
          hij_end_date: this.hij_end_date,
          trans_with_coord: this.trans_with_coord
        },
      }

      if (this.plan_no.length) {
        let newDate = {
          start_date: this.yearRange.length ? this.yearRange[0] : "",
          end_date: this.yearRange.length ? this.yearRange[1]: "",
        }
        let payload2 = {
          meta: { stat_type: 'plan', stat_type_value: this.plan_no[0] }
        };
        if (this.yearRange.length) payload2 = {meta: {...payload2.meta, ...newDate}}
        this.stat_loading = true
        this.$store.dispatch('transaction/fetchStatOne', payload2)
            .then(() => {
              this.stat_loading = false
              this.login_error = null
            })
            .catch(error => {
              this.stat_loading = false
              this.login_error = error
            })
        let payload3 = {meta: { plan: this.plan_no[0] }}
        if (this.city_id) payload3 = {meta: {...payload3.meta, city_id: this.city_id }}
        if (this.yearRange.length) payload3 = {meta: {...payload3.meta, ...newDate}}
        this.$store.dispatch('transaction/fetchStatTwo', payload3)
      }

      for (const [key, value] of Object.entries(payload.meta)) {
        if(value == "")
          delete payload.meta[key]
      }
      this.$store.dispatch("transaction/fetchTransactions", payload)
      .then(() => this.transact_loading = false)
      .catch(() => this.transact_loading = false)
    },
    fetchAllCities() {
      const payload = {
        meta: {
          page: 1,
          paginate: 200
        },
      }
      this.$store.dispatch("city/fetchAllCities", payload)
    },
    toggleFilter() {
      this.filterActive = !this.filterActive
    },
    transText(value) {
      return value ? i18n.ar[value.toLowerCase().split(" ").join("_")] : ''
    },
    exportData() {
      const data = JSON.parse(JSON.stringify(this.transactions))
      const fileName = "transactions"
      const exportType = "csv"

      exportFromJSON({ data, fileName, exportType})
    },
    joinCoords(coord) {
      return (coord === null) ? "" : coord.join(",")
    },
    formatNumber(number) {
      return utils.numberFormat(number)
    },
    onOptionClick({ option, addTag }) {
      addTag(option); this.term = ''
      setTimeout(() => this.fetchTransactions(), 500)
    },
    fetchPlans(query) {
      let payload = { meta: { q: query, paginate: 1000 } }
      if (this.city_id) payload = {...payload, meta: { ...payload.meta, city_id: this.city_id}}
      query.length > 1 && this.$store.dispatch("plan/fetchPlans2", payload)
    },
    clearPlans() {
      this.plan_no = []
      this.$store.dispatch("plan/clearPlans")
    }
  },
  created() {
    this.$store.dispatch("transaction/fetchClassifications", {meta: {page: 1, paginate: 200}})
    this.fetchTransactions()
    this.fetchAllCities()
  }

}
</script>

<style lang="scss">
.chart-title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-top: 25px;
  color: #333;
}
.error-title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
  color: #333;
  width: 100%;
}
.spinner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.dropdown-menu.show {
  overflow: scroll;
  height: 300px;
}
</style>
